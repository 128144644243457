/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiWallet, mdiGenderFemale, mdiWeb } from '@mdi/js';
import './sidebar-menu.m.css';
import { BQLink } from '../../atoms/bqlink';
import { get } from 'lodash';
import { SOCIAL_SHARE, CONTACT } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import SvgIconHandler from '../../atoms/svg-icon-handler';
import { OPEN_HAMBURGER_MENU } from '../../store/actions';

const SideBarMenu = ({ menu }) => {
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const showMenu = get(getState, ['isHamburgerMenuOpen'], false);
  const worldsummit = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'worldsummit'], {})
  );
  const handleMenuClose = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: false
    });
  };

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [showMenu]);

  return (
    <div styleName={`megamenu-wrapper ${showMenu ? 'showMenu' : 'hideMenu'}`} id="mega-menu">
      <div styleName="mega-wrap">
        <div styleName="megamenu-top-section">
          <div styleName="logo-section">
            <div>
              <SvgIconHandler type="NDTV_hindi_logo" width="168px" source="image" isSvg={false} />
            </div>
            <div styleName="close-icon" onClick={handleMenuClose}>
              <SvgIconHandler type="close_original" height="18px" width="18px" />
            </div>
          </div>
          <a
            href="https://www.ndtvprofit.com/?utm_source=profithindi&utm_medium=referral&utm_campaign=language"
            styleName="switch-to-english"
          >
            <SvgIconHandler type="language-toggle-icon" width="22px" height="22px" source="image" />
            <div styleName="switch-to-english-text">Switch to English</div>
          </a>
        </div>
        <div styleName="border-above-bottom-section">
          <span></span>
        </div>
        <div styleName="megamenu-bottom-section">
          <div styleName="menu-list">
            {menu.length > 0
              ? menu.map((item) => {
                  if (item.title === 'बजट 2024') {
                    return (
                      <BQLink href={item.completeUrl + '?src=menu'} styleName="menu-item">
                        <div styleName="icon-wrapper budget-menu">
                          <SvgIconHandler
                            type={`budget-menu-icon`}
                            width="23px"
                            height="23px"
                            isSvg={true}
                          />
                          <img
                            key="budget-icon"
                            src={`/icons/budget_menu_icon.png`}
                            alt="NDTV Profit हिंदी"
                            styleName="budget-title"
                          />
                        </div>
                      </BQLink>
                    );
                  } else if (item.title === worldsummit.name) {
                    return (
                      <a
                        href={`${worldsummit?.link}?${worldsummit?.utm}`}
                        styleName="menu-item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div styleName="icon-wrapper world-icon">
                          <Icon path={mdiWeb} size="23px" styleName="mdi-menu-icons" />
                          <img
                            src={worldsummit?.desk_img}
                            height={worldsummit?.mb_height}
                            alt={worldsummit?.name}
                          />
                        </div>
                      </a>
                    );
                  } else {
                    return (
                      <BQLink href={item.completeUrl + '?src=menu'} styleName="menu-item">
                        <div styleName="icon-wrapper">
                          {item['section-slug'] === 'women' ? (
                            <Icon path={mdiGenderFemale} size="23px" styleName="mdi-menu-icons" />
                          ) : item['section-slug'] === 'personal-finance' ? (
                            <Icon path={mdiWallet} size="23px" styleName="mdi-menu-icons" />
                          ) : (
                            <SvgIconHandler
                              type={`${item['section-slug']}-menu-icon`}
                              isSvg={true}
                              width="20px"
                              height="20px"
                            />
                          )}
                        </div>
                        <div styleName="parent-menu-item-title">{item.title}</div>
                      </BQLink>
                    );
                  }
                })
              : null}
          </div>
          <div styleName="user-options-with-social">
            <div styleName="user-options-section">
              <a href={`mailto:${CONTACT.SUPPORT_ID}`}>
                {/* <LifeBuoyIcon color="#b7bfc4" size="24px" /> */}
                <SvgIconHandler type="life_boy" width="20px" height="20px" />
                <span>Support</span>
              </a>
              <BQLink href="/about-us?src=menu">
                {/* <InformationOutlineIcon color="#b7bfc4" size="24px" /> */}
                <SvgIconHandler type="info-icon-grey" width="20px" height="20px" />
                <span>About NDTV Profit हिंदी</span>
              </BQLink>
            </div>
            <div styleName="social-section">
              <div styleName="socials-title">FOLLOW NDTV Profit हिंदी ON</div>
              <div styleName="social-icons-footer-menu">
                <a href={SOCIAL_SHARE.FACEBOOK} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="facebook_grey" width="36px" height="36px" />
                </a>
                <a href={SOCIAL_SHARE.INSTAGRAM} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="instagram_grey" width="36px" height="36px" />
                </a>
                <a href={SOCIAL_SHARE.LINKEDIN} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="linkedin_grey" width="36px" height="36px" />
                </a>
                <a href={SOCIAL_SHARE.YOUTUBE} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="youtube_grey" width="36px" height="36px" />
                </a>
                <a href={SOCIAL_SHARE.TWITTER} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="x_grey" width="36px" height="36px" />
                </a>
                <a href={SOCIAL_SHARE.TELEGRAM} target="_blank" rel="noreferrer">
                  <SvgIconHandler type="telegram_grey" width="36px" height="36px" />
                </a>
              </div>
            </div>
            {/* <a
            href="https://news.google.com/publications/CAAqBwgKMPmkxAswnMDbAw?ceid=IN:hi&oc=3&hl=hi&gl=IN"
            styleName="follow-on-google-news"
          >
            <SvgIconHandler
              type="follow-on-google-news-sidebar"
              height="30px"
              width="max-content"
              source="image"
            />
            <SvgIconHandler
              type="ic_arrow_forward_grey_24px"
              height="15px"
              width="max-content"
              source="image"
            />
          </a> */}
            <div styleName="app-promotion-section">
              <div styleName="app-version">
                {/* <CellPhoneIcon color={'#8f9da5'} size="16px" /> */}
                <SvgIconHandler type="cellphone" width="15px" height="15px" />
                <span>v1.0.1</span>
              </div>
              <div styleName="app-message">
                Made with
                {/* <HeartIcon color="var(--submarine)" size="18px" /> */}
                <SvgIconHandler type="heart" width="16px" height="16px" />
                by Business Experts
              </div>
            </div>
          </div>
        </div>
      </div>
      <div styleName="blank-menu"></div>
    </div>
  );
};

export default SideBarMenu;
