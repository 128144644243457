import React from 'react';
import PT from 'prop-types';

import './hamburger-menu.m.css';

export const HamburgerMenu = ({ onMenuToggle, isMegaMenuOpen }) => (
  <button
    aria-label="Hamburger Menu"
    className="hamburger-menu"
    onClick={onMenuToggle}
    styleName={`hamburger ${isMegaMenuOpen ? 'is-open' : ''}`}
  >
    <div>
      {new Array(3).fill(null).map((element, i) => {
        return <span styleName="line" key={i + 1} />;
      })}
    </div>
    <div styleName="explore-text">Menu</div>
  </button>
);

HamburgerMenu.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool
};

export default HamburgerMenu;
