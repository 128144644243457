import React from 'react';
import { string, bool } from 'prop-types';

const SvgIcon = ({ type, isSvg, className = '', width = '100%', height = '100%' }) => {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src={`/icons/${type}.${isSvg ? 'svg' : 'png'}`}
      alt="NDTV Profit हिंदी"
    />
  );
};

SvgIcon.propTypes = {
  type: string,
  className: string,
  width: string,
  height: string,
  isSvg: bool
};

SvgIcon.defaultProps = {
  isSvg: true
};

export default SvgIcon;
