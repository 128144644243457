import React from 'react';
import { LoadingIndicator } from '@quintype/components';
import SvgIconHandler from '../svg-icon-handler';
import './loading-indicator.m.css';

const LoadingIndicatorComponent = () => (
  <LoadingIndicator>
    <div styleName="qt-loading-animation">
      <SvgIconHandler type="ndtv-hindi-logo" width="200px" height="150px" />
    </div>
  </LoadingIndicator>
);

export default LoadingIndicatorComponent;
