/* eslint-disable max-len */
import get from 'lodash/get';
import find from 'lodash/find';

export function getPrimarySection(sections) {
  if (sections) return sections[0];
  return {};
}

export function getStoryHeaderData({
  'hero-image-s3-key': heroImageS3Key,
  'hero-image-metadata': heroImageMetaData,
  'hero-image-caption': heroImageCaption,
  headline,
  metadata,
  alternative
}) {
  const storyTemplateAttribute = get(metadata, ['story-attributes', 'storytemplate', '0'], '');
  return {
    headline,
    heroImageData: {
      heroImageS3Key:
        heroImageS3Key ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-s3-key'], ''),
      heroImageMetaData:
        heroImageMetaData ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-metadata'], {}),
      heroImageCaption:
        heroImageCaption ||
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-caption'], null)
    },
    headlineFallback: get(alternative, ['home', 'default', 'headline']) || headline,
    heroImageFallback: {
      'hero-image-s3-key':
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-s3-key']) || heroImageS3Key,
      'hero-image-metadata':
        get(alternative, ['home', 'default', 'hero-image', 'hero-image-metadata']) ||
        heroImageMetaData
    },
    storyTemplateAttribute
  };
}

export function hasSection(sections, sectionName) {
  return find(sections, (section) => section.name === sectionName);
}

export function getSyndicatedSource(story) {
  return get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0']);
}

export function getSourceDisplayName(source) {
  if (source === 'thequint') {
    return 'TheQuint';
  } else if (source === 'bloomberg') {
    return 'Bloomberg';
  } else if (source === 'vidible') {
    return 'Vidible';
  }
  return 'NDTV Profit';
}

export function heroImageFallback(story) {
  const externalHeroImage = get(story, ['metadata', 'external-hero-image']);
  const alternativeHeroImage = get(story, ['alternative', 'home', 'default', 'hero-image']);
  const heroImageKey = get(story, ['hero-image-s3-key']);
  if (externalHeroImage && externalHeroImage.url) {
    return externalHeroImage.url;
  }
  if (alternativeHeroImage) {
    return alternativeHeroImage;
  }
  if (heroImageKey && heroImageKey !== '') {
    return story;
  }
  return false;
}

export function getThinmintCookie() {
  return document.cookie.split('; ').filter((item) => item.startsWith('thinmint='));
}

export function getThinmintId() {
  const thinmintCookie = getThinmintCookie();
  return thinmintCookie.length && thinmintCookie[0].split('=')[1]
    ? thinmintCookie[0].split('=')[1]
    : '0';
}

export function formatStoriesObject(stories) {
  return stories.map((story) => ({
    id: story.Cid,
    slug: story.Slug,
    headline: story.Description,
    metadata: story.ImageMetaData && JSON.parse(story.ImageMetaData),
    publishedAt: story.first_published_at,
    imgUrl: story.ImageUrl
  }));
}

// function jsonUrlEncoded(element, key, list = []) {
//   if (typeof element === 'object') {
//     for (var idx in element) jsonUrlEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
//   } else {
//     list.push(key + '=' + encodeURIComponent(element));
//   }
//   return list.join('&');
// }

export function sendCrmData(data) {
  // fetch('https://apizoho.bquint.info/vendor/api.php', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  //   body: jsonUrlEncoded(data)
  // });
}

export function getTextElementsOfCards(story) {
  if (story && story.cards) {
    return story.cards.reduce((acc, currentCard) => {
      return acc.concat(currentCard['story-elements'].filter((element) => element.type === 'text'));
    }, []);
  }
}

export function getPlainText(text = '') {
  return text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
}

export function getCompleteText(story) {
  const textArray = [];
  getTextElementsOfCards(story).forEach((item) => {
    const textContent = getPlainText(item.text);
    textArray.push(textContent);
  });
  const completeCardText = textArray.join('.');
  return JSON.stringify(completeCardText);
}

export function getCardText(card) {
  const textArray = [];
  card['story-elements'].forEach((item) => {
    const textContent = getPlainText(item.text);
    textArray.push(textContent);
  });
  const completeCardText = textArray.join('.');
  return completeCardText;
  // return JSON.stringify(completeCardText.replace(/\\"/g, "'").replace(/"/g, "'"));
}
