import React from 'react';
import { SOCIAL_ICONS_NEW, STRINGS } from './../../../../constants';
import '../footer.m.css';
import SvgIcon from '../../../atoms/svg-icon-handler';
import { Link } from 'react-scroll';
// import WhatsappOptinModal from './../../../molecules/whatsapp-optin-modal';

const SocialShare = () => {
  return (
    <div styleName="list">
      {/* {openModal && <WhatsappOptinModal showModal={openModal} toggle={setOpenModal} />} */}
      <h3 styleName="heading" className="desktop-only">
        {STRINGS.FOLLOW_US}
      </h3>
      <h3 styleName="heading" className="mobile-only">
        {STRINGS.NEW_FOLLOW_US_TITLE}
      </h3>
      <nav styleName="social-icons">
        {/* <a
          styleName="item link-color"
          rel="noopener noreferrer"
          href="#"
          onClick={modalOpenHandler}
        >
          <i styleName="icon" className="icon-ic_whatsapp_circle" />
        </a> */}
        {SOCIAL_ICONS_NEW &&
          SOCIAL_ICONS_NEW.map((item, index) => (
            <Link
              key={index}
              styleName="item link-color"
              target="_blank"
              href={item.url}
              rel="noreferrer"
            >
              <SvgIcon type={item.icon_code} isSvg={false} />
            </Link>
          ))}
      </nav>
    </div>
  );
};

export default SocialShare;
