import React from 'react';
import { any, string } from 'prop-types';
import { Link } from '@quintype/components';

export const BQLink = (props) => {
  const linkValue =
    props && props.href && props.href.startsWith('/') ? props.href : `/${props.href}`;
  return (
    <Link {...props} href={linkValue} disableAjaxLinks>
      {props.children}
    </Link>
  );
};

BQLink.defaultProps = {
  href: ''
};

BQLink.propTypes = {
  children: any,
  href: string
};
