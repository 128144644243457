import React from 'react';
import { string } from 'prop-types';
import DfpAd from './../../atoms/dfp-ad';
import './mobile-bottom-sticky.m.css';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { isMobile } from 'react-device-detect';

const MobileBottomSticky = ({ pageType }) => {
  return (
    <>
      {isMobile && (
        <div styleName="sticky-wrapper">
          <div styleName="sticky-responsive-ad-wrapper">
            <div styleName="sticky-responsive-ad">
              <DfpAd
                adtype="MobileSticky"
                adUnit="Sticky_320x75"
                sizes={[[320, 75], [320, 50]]}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

MobileBottomSticky.propTypes = {
  pageType: string
};

const mapStateToProps = (state) => {
  return {
    pageType: get(state, ['qt', 'pageType'], '')
  };
};

export default connect(mapStateToProps)(MobileBottomSticky);
