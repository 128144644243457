import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';

import { BQLink } from './../../atoms/bqlink';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import LinkList from './../../molecules/link-list';
import CopyrightSection from './../../molecules/copyright-section';
// import DisclaimerSection from './../../molecules/disclaimer-section';
import SocialShare from './social-share-icon-list/index';
import {
  BLOOMBERGQUINT_FOOTER_LINKS,
  CONTACT,
  STRINGS,
  FOOTER_CONTACT_US
} from './../../../constants';

import './footer.m.css';

const FooterC = (props) => {
  const getState = useSelector((state) => state);
  const footer = get(getState, ['qt', 'data', 'navigationMenu', 'footer'], []);
  const footerVideos = get(getState, ['qt', 'data', 'navigationMenu', 'footerVideosMenu'], []);
  const [flag, setFlag] = useState(true);
  // const [isPartnerPage, setIsPartnerPage] = useState(false);

  useEffect(() => {
    // const pathName = window.location.pathname;
    // const routes = ['/bq-blue-subscription', '/bq-partners'];
    // setIsPartnerPage(routes.includes(pathName));
    setTimeout(() => {
      setFlag(true);
    }, 3000);
  }, []);

  return flag ? (
    <>
      <footer styleName="footer" id="bq-footer">
        <div styleName="fh-support-links-container">
          <div styleName="fh-support-links-desktop" className="desktop-only container">
            <div styleName="bqprime-blue">
              <BQLink href="/">
                <img src="/icons/NDTV_hindi_logo.png" alt="NDTV Profit हिंदी" />
              </BQLink>
              <div styleName="language-toggle" className="desktop-only">
                <div styleName="toggle-btn-container">
                  <button styleName="toggle-btn toggle-selected">हिंदी</button>
                  <a
                    href="https://ndtvprofit.com?utm_source=profithindi&utm_medium=referral&utm_campaign=language"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button styleName="toggle-btn">English</button>
                  </a>
                </div>
              </div>
            </div>
            <div styleName="get-in-touch">
              <div styleName="contact-cards">
                <div styleName="get-img">
                  <img src="/icons/get_in_touch.png" alt="get_in_touch" />
                  <div styleName="title">
                    Get in <span>Touch</span>
                  </div>
                </div>
              </div>
              <a styleName="contact-support" href={`mailto:${CONTACT.EDITOR_ID}`}>
                <div styleName="emai">
                  {STRINGS.EDITORIAL_FEEDBACK}
                  <br></br>
                  <div styleName="email">
                    {CONTACT.EDITOR_ID}&nbsp;
                    <Icon path={mdiChevronRight} size="20px" styleName="get-icon" />
                  </div>
                </div>
              </a>

              <a styleName="contact-support" href={`mailto:${CONTACT.SALES_ID}`}>
                <div styleName="emai">
                  {STRINGS.SALES}
                  <br></br>
                  <div styleName="email">
                    {CONTACT.SALES_ID}
                    &nbsp;
                    <Icon path={mdiChevronRight} size="20px" styleName="get-icon" />
                  </div>
                </div>
              </a>

              <a
                styleName="contact-support"
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${CONTACT.SUPPORT_ID}`}
              >
                <div styleName="emai">
                  {STRINGS.SUPPORT}
                  <br></br>
                  <div styleName="email">
                    {CONTACT.SUPPORT_ID}
                    &nbsp;
                    <Icon path={mdiChevronRight} size="20px" styleName="get-icon" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div styleName="f-container" className="container">
          <div styleName="f-mobile-container">
            <div styleName="fh-bq-logo-mobile" className="mobile-only">
              <BQLink href="/">
                <div styleName="first-icon">
                  <img
                    src="/icons/NDTV_hindi_logo.png"
                    alt="NDTV Profit हिंदी"
                    width="264px"
                    height="84px"
                  />
                </div>
              </BQLink>

              <div styleName="language-switcher" className="mobile-only">
                <span style={{ fontWeight: 'var(--font-weight-bold' }}>हिंदी</span>
                <span>|</span>

                <a
                  href="https://www.ndtvprofit.com/?utm_source=profithindi&utm_medium=referral&utm_campaign=language"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>English</span>
                </a>
              </div>
            </div>

            <div styleName="fh-support-links-mobile" className="mobile-only">
              <div styleName="support-links-container">
                <h6 styleName="headline">{FOOTER_CONTACT_US.CONTACT_ID}</h6>
                {FOOTER_CONTACT_US.CONTACT_LIST.map((i, index) => {
                  return (
                    <a styleName="links-section" key={index} href={i.link}>
                      <div styleName="icon-txt-wrap">
                        <img src={i.path} alt={i.title} height="40px" width="40px" />
                        <div styleName="txt-wrap">
                          <span styleName="title">{i.title} </span>
                          <span styleName="email">{i.email}</span>
                        </div>
                      </div>
                      <Icon path={mdiChevronRight} size="20px" color="#8F9DA5" />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <div styleName="siteinfo-section">
            <div styleName="list-section">
              <LinkList
                links={footer}
                sectionHeading={BLOOMBERGQUINT_FOOTER_LINKS.header}
                accordion={false}
                styleName="si-section-list"
              />
            </div>
            <div styleName="list-section">
              <LinkList
                links={footerVideos}
                sectionHeading={'VIDEOS'}
                accordion={false}
                styleName="si-section-list"
                gridStyle="video-list"
              />
            </div>
            <div styleName="list-section">
              <SocialShare />
              {/* <div styleName="stock-exchange-msg">
                  Note : All Stock Exchange data is delayed upto 3 mins
                </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* <DisclaimerSection /> */}

      <CopyrightSection />
    </>
  ) : null;
};

const mapStateToProps = (state) => {
  const publisherConfig = state.qt.config.publisher;
  return {
    typeFormConfig: get(publisherConfig, ['typeform'], '')
  };
};

const Footer = connect(mapStateToProps, null)(FooterC);

export { Footer };
