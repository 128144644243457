import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { OPEN_SEARCHBAR } from '../../../../../components/store/actions';

import './mobile-search.m.css';
import SvgIconHandler from '../../../../atoms/svg-icon-handler';

const MobileSearch = () => {
  const inputRefMobile = useRef(null);

  const [suggestions, setSuggestion] = useState([]);
  const [searchtext, setSearchtext] = useState('');

  useEffect(() => {
    if (suggestions?.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [suggestions]);

  const getStory = (slug) => {
    window.location.href = `/${slug}`;
  };

  const getSuggestion = () => {
    setSearchtext(inputRefMobile?.current?.value?.toLowerCase()?.trim());
    fetch(
      `/api/v1/search?q=${inputRefMobile?.current?.value?.toLowerCase()}&fields=headline,id,author-name,slug&limit=100`
    )
      .then((res) => res.json())
      .then((res) => setSuggestion(res.results.stories));
  };

  const getSuggestionDebounced = debounce(getSuggestion, 700);

  const dispatch = useDispatch();
  const isSearchBarOpen = useSelector((state) => get(state, ['isSearchBarOpen'], false));
  const toggleSearchForm = () => {
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: !isSearchBarOpen
    });
  };

  useEffect(() => {
    if (isSearchBarOpen) {
      inputRefMobile?.current?.focus();
    }
  }, [isSearchBarOpen]);

  const closeSearchBar = () => {
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
    setSuggestion([]);
    inputRefMobile.current.value = '';
  };
  return (
    <>
      <div styleName={`search ${isSearchBarOpen ? 'search-open' : ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24.003" fill='#b7bfc4' onClick={() => toggleSearchForm()}><defs><style></style></defs><g transform="translate(-333.501 -120.499)"><path class="a" d="M4140.668-13891.317l-6.194-6.194a9.772,9.772,0,0,1-6.127,2.152,9.83,9.83,0,0,1-9.818-9.822,9.829,9.829,0,0,1,9.818-9.819,9.829,9.829,0,0,1,9.818,9.819,9.777,9.777,0,0,1-2.15,6.128l6.2,6.192a1.1,1.1,0,0,1,0,1.544,1.1,1.1,0,0,1-.773.319A1.088,1.088,0,0,1,4140.668-13891.317Zm-19.96-13.864a7.648,7.648,0,0,0,7.639,7.639,7.644,7.644,0,0,0,7.635-7.639,7.643,7.643,0,0,0-7.635-7.636A7.647,7.647,0,0,0,4120.708-13905.182Z" transform="translate(-3785.027 14035.5)"/></g></svg>
        <div styleName="search-form">
          <div styleName="searchIconMobile">
            <SvgIconHandler type="search_icon" width="18px" height="18px" />
          </div>
          <form role="submit" action="/search" method="get">
            <input
              ref={inputRefMobile}
              name="q"
              type="text"
              onChange={getSuggestionDebounced}
              autoComplete="off"
              placeholder="Search here..."
              styleName="globalSearch"
            />
          </form>
          <div styleName="close-icon" onClick={() => closeSearchBar()}>
            &#x2716;
          </div>
        </div>
        {suggestions && suggestions?.length > 0 && (
          <div styleName="suggestions-autofill-container_mobile">
            {searchtext &&
              suggestions?.map((item) => {
                if (
                  item?.headline?.toLowerCase()?.includes(searchtext?.toLowerCase()) ||
                  get(item, ['author-name'], '')?.toLowerCase()?.includes(searchtext?.toLowerCase())
                ) {
                  return (
                    <div
                      onClick={() => getStory(item?.slug)}
                      key={item?.id}
                      styleName="suggestionBox_mobile"
                    >
                      <span>{item?.headline}</span>
                      <small>{get(item, ['author-name'], '')}</small>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default MobileSearch;
