import React, { useState, useEffect, useMemo } from 'react';
import { string, bool, objectOf, any, func, array } from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withError } from '@quintype/components';
import { AdSlot, DFPManager } from 'react-dfp';

const targeting = state => {
  const params = {
    environment: get(state.qt, ['config', 'publisher', 'environment']) || state.qt.config.env,
    adfree: global.adfree,
    referralsource: global.referralsource,
    type: get(state, ['qt', 'pageType'], null)
  };

  if (get(state, ['qt', 'pageType']) === 'home-page') {
    params.section = 'home';
  }
  if (get(state, ['qt', 'pageType']) === 'story-page') {
    params.type = 'story';
    params.section = get(state, ['qt', 'data', 'story', 'sections', '0', 'name']);
    if (get(state, ['qt', 'data', 'story', 'metadata', 'sponsored-by'])) {
      params.sponsor = get(state, ['qt', 'data', 'story', 'metadata', 'sponsored-by']);
    }
  }

  if (get(state, ['qt', 'pageType']) === 'section-page') {
    params.type = 'section';
    params.section = get(state, ['qt', 'data', 'section', 'name'], '');
  }

  params.path = get(state, ['qt', 'currentPath']);

  return params;
};

const DfpAd = ({
  defaultNetworkID,
  config,
  targetingArguments,
  adtype,
  slotId,
  collectionName,
  disableDfp,
  collapseEmptyDivs,
  onSlotIsViewable,
  onSlotRender,
  isSubscriber,
  adUnit,
  sizes,
  pageType
}) => {
  const [delayRender, setDelayRender] = useState(false);
  const [isDFPManagerLoaded, setIsDFPManagerLoaded] = useState(false);
  const [dynamicAdUnitName, setDynamicAdUnitName] = useState('');

  useEffect(() => {
    if (!disableDfp) {
      DFPManager.setCollapseEmptyDivs(collapseEmptyDivs);
      DFPManager.load([slotId || adtype]);

      const getSection = /^\/([a-zA-Z0-9-_]+)/.exec(targetingArguments.path);
      const section = getSection ? getSection[1] : null;
      // Initialize googletag if not already initialized
      window.googletag = window.googletag || { cmd: [] };

      window.googletag.cmd.push(() => {
        if (section) {
          if (window.googletag.pubads && typeof window.googletag.pubads === 'function') {
            window.googletag.pubads().setTargeting('section', section);
            console.log("The 'section' variable is available. Targeting is set.");
          } else {
            console.error('googletag.pubads is not a function');
          }
        } else {
          console.log("The 'section' variable is null or undefined. Targeting not set.");
        }
      });

      if (adtype === 'PopUpAcrossAllSectionsAndPages') {
        console.log('PopUpAcrossAllSectionsAndPages available');
      }
    }

    setIsDFPManagerLoaded(true);

    // const hasSession = window.sessionStorage.getItem('SESSION');
    const TIMER_DELAY = 2000; // delaying ads from loading by 5 seconds

    const timeout = setTimeout(() => {
      setDelayRender(true);
    }, TIMER_DELAY);
    return () => clearTimeout(timeout);
  }, [disableDfp, slotId, adtype, targetingArguments, collapseEmptyDivs]);

  useEffect(() => {
    let formattedCategory; // Declare formattedCategory outside the if block
    if (typeof window !== 'undefined') {
      const deviceType = window.innerWidth <= 768 ? 'WAP' : 'Desktop';
      const category = window.location.pathname.substring(1).split('/')[0];
      formattedCategory = pageType === 'home-page' ? 'Homepage' : (category.replace(/[-\s]/g, ''));
      if (typeof adUnit === 'number') {
        formattedCategory = 'ROS';// not working currently
      }
      const name = `Profit_Hin/${deviceType}/${pageType === 'home-page' ? 'Home' : (pageType === 'story-page' ? 'Detail' : (pageType === 'market-page' ? 'Section' : 'Listing'))}/${formattedCategory}`;
      setDynamicAdUnitName(name);
    }
  }, [pageType, adUnit]);

  const updatedAdUnit = `${dynamicAdUnitName}/${adUnit}`;

  const adConfig = useMemo(() => {
    return config[adtype] || {};
  }, [adtype, config]);

  return !disableDfp && isDFPManagerLoaded && delayRender ? (
    <AdSlot
      dfpNetworkId={`/${defaultNetworkID}`}
      targetingArguments={{ ...targetingArguments, collectionName }}
      adUnit={updatedAdUnit}
      sizes={sizes}
      sizeMapping={adConfig.viewPortSizeMapping}
      slotId={slotId || adtype}
      onSlotIsViewable={onSlotIsViewable}
      onSlotRender={onSlotRender}
    />
  ) : null;
};

DfpAd.propTypes = {
  defaultNetworkID: string.isRequired,
  config: objectOf(any).isRequired,
  targetingArguments: objectOf(any).isRequired,
  adtype: string,
  slotId: string,
  adUnit: string,
  sizes: array,
  pageType: string,
  collapseEmptyDivs: bool,
  disableDfp: bool,
  collectionName: string,
  onSlotIsViewable: func,
  onSlotRender: func,
  isSubscriber: bool.isRequired,
};

DfpAd.defaultProps = {
  slotId: null,
  collapseEmptyDivs: true,
  collectionName: '',
  onSlotIsViewable: () => { },
  onSlotRender: () => { }
};

const mapStateToProps = state => ({
  defaultNetworkID: get(state, ['qt', 'config', 'ads', 'network-id'], ''),
  config: get(state, ['qt', 'config', 'ads', 'slots'], ''),
  disableDfp: get(state, ['qt', 'config', 'features', 'disable_dfp', 'should_disable'], false),
  targetingArguments: targeting(state),
  isSubscriber: get(state, ['isSubscriber', 'isSubscriber'], true),
  pageType: get(state, ['qt', 'pageType'], 'home')
});

export default connect(mapStateToProps, () => ({}))(withError(DfpAd));
