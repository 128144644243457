import React, { useState } from 'react';
import { string, node, bool } from 'prop-types';

import './accordion.m.css';

import Button from '../Button';
import AngleDownIcon from './../icons/angle-down';
const Accordion = ({
  visibleWindowHeight = '220px',
  children,
  disable = false,
  buttonText = 'और देखें',
  iconColor = 'fourth-color',
  variant = 'secondary',
  className
}) => {
  const [height, setHeight] = useState(visibleWindowHeight);
  const [windowVisibility, setWindowvisibility] = useState('hide');

  return (
    <>
      <div styleName="acc-container-mobile" className={className}>
        {disable ? (
          children
        ) : (
          <>
            <div
              styleName="acc-child-element"
              style={{
                height: height
              }}
            >
              {children}
            </div>
            {windowVisibility === 'hide' ? (
              <Button
                styleName="button"
                className="show-button"
                variant={variant}
                label={buttonText}
                onClick={() => {
                  setHeight('100%');
                  setWindowvisibility('show');
                }}
                style={{
                  justifyContent: 'center',
                  color: iconColor,
                  width: '100%'
                }}
              >
                <AngleDownIcon color={iconColor} />
              </Button>
            ) : (
              <div>
                <Button
                  styleName="button angleRotateUp"
                  className="show-button"
                  variant={variant}
                  label="Show Less"
                  onClick={() => {
                    setHeight(visibleWindowHeight);
                    setWindowvisibility('hide');
                  }}
                  style={{
                    marginTop: '15px',
                    justifyContent: 'center',
                    color: iconColor,
                    width: '100%'
                  }}
                >
                  <AngleDownIcon color={iconColor} />
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div styleName="acc-container-desktop" className={className}>
        {children}
      </div>
    </>
  );
};
Accordion.propTypes = {
  children: node,
  visibleWindowHeight: string,
  disable: bool,
  className: string,
  buttonColor: string,
  buttonText: string,
  iconColor: string,
  variant: string
};

export default Accordion;
