import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { Link } from '@quintype/components';

import './menu-item.m.css';

const MenuItem = ({ item, toggleHandler, hasChildren }) => {
  const [currentSubmenu, setCurrentSubmenu] = useState('');

  if (item['item-type'] === 'placeholder') {
    return <span styleName="menu-link">{item.title}</span>;
  } else if (item['item-type'] === 'link') {
    return (
      <a
        onClick={toggleHandler}
        target="_blank"
        rel="noopener noreferrer"
        href={item.completeUrl || '/'}
      >
        {item.title}
      </a>
    );
  } else {
    return (
      <div styleName="ham-navlink">
        <Link aria-label="menu-item" callback={toggleHandler} href={`${item.completeUrl || '/'}?src=topnav`}>
          {item.title}
        </Link>
        {currentSubmenu === item.title &&
          hasChildren &&
          item?.children.map((item, i) => (
            <a
              styleName="ham-sub-link"
              aria-label="menu-item"
              callback={toggleHandler}
              href={`${item.completeUrl || '/'}?src=topnav`}
              key={i}
            >
              {item.title}
            </a>
          ))}
        {hasChildren && currentSubmenu !== item.title && (
          <span onClick={() => setCurrentSubmenu(item.title)}>+</span>
        )}
        {hasChildren && currentSubmenu === item.title && (
          <span onClick={() => setCurrentSubmenu('')}>-</span>
        )}
      </div>
    );
  }
};

MenuItem.propTypes = {
  item: PT.object,
  showIcon: PT.bool,
  hasChildren: PT.bool
};

MenuItem.defaultProps = {
  hasChildren: false
};

export { MenuItem };
